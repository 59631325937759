var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "pt-0 overflow-y-auto",
    attrs: {
      "fluid": ""
    }
  }, [_c('v-card', {
    staticStyle: {
      "max-width": "1200px"
    },
    attrs: {
      "disabled": _vm.loading,
      "loading": _vm.loading
    }
  }, [_c('v-card-title', {
    staticClass: "primary white--text text-h6"
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "dark": ""
    }
  }, [_vm._v("mdi-transit-connection-horizontal")]), _vm._v("Workflow ")], 1), _c('v-card-text', [_c('v-list', {
    staticClass: "transparent"
  }, [_c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v("Import Job Details - Default Status")]), _c('v-list-item-subtitle', [_vm._v(" The status suggested when importing jobs details from a prescription. ")])], 1), _c('v-list-item-action', [_c('LazyDropdown', {
    attrs: {
      "url": 'jobStatus',
      "placeholder": "Status",
      "item-text": "name",
      "item-value": "xid",
      "outlined": "",
      "dense": ""
    },
    on: {
      "input": _vm.updateImportStatus
    },
    model: {
      value: _vm.workflow.import_status,
      callback: function callback($$v) {
        _vm.$set(_vm.workflow, "import_status", $$v);
      },
      expression: "workflow.import_status"
    }
  })], 1)], 1), _c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v("Import Job Details - QMS Step")]), _c('v-list-item-subtitle', [_vm._v(" Automatically log a QMS activity for the user who imports job details from a prescription. ")])], 1), _c('v-list-item-action', [_c('LazyDropdown', {
    attrs: {
      "url": 'qms/steps',
      "placeholder": "QMS Step",
      "item-text": "title",
      "item-value": "xid",
      "outlined": "",
      "dense": ""
    },
    on: {
      "input": _vm.updateImportStep
    },
    model: {
      value: _vm.workflow.import_step,
      callback: function callback($$v) {
        _vm.$set(_vm.workflow, "import_step", $$v);
      },
      expression: "workflow.import_step"
    }
  })], 1)], 1), _c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v("Completion Status")]), _c('v-list-item-subtitle', [_vm._v(" The status a job moves to when the "), _c('i', [_vm._v("Complete Job")]), _vm._v(" automation is run. ")])], 1), _c('v-list-item-action', [_c('LazyDropdown', {
    attrs: {
      "url": 'jobStatus',
      "placeholder": "Status",
      "item-text": "name",
      "item-value": "xid",
      "outlined": "",
      "dense": ""
    },
    on: {
      "input": _vm.updateCompletedStatus
    },
    model: {
      value: _vm.workflow.completed_status,
      callback: function callback($$v) {
        _vm.$set(_vm.workflow, "completed_status", $$v);
      },
      expression: "workflow.completed_status"
    }
  })], 1)], 1), _c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v("Completion Step")]), _c('v-list-item-subtitle', [_vm._v(" The QMS Step to log when the "), _c('i', [_vm._v("Complete Job")]), _vm._v(" automation is run. ")])], 1), _c('v-list-item-action', [_c('LazyDropdown', {
    attrs: {
      "url": '/qms/steps',
      "placeholder": "QMS Step",
      "item-text": "title",
      "item-value": "xid",
      "outlined": "",
      "dense": ""
    },
    on: {
      "input": _vm.updateCompletedStep
    },
    model: {
      value: _vm.workflow.completed_step,
      callback: function callback($$v) {
        _vm.$set(_vm.workflow, "completed_step", $$v);
      },
      expression: "workflow.completed_step"
    }
  })], 1)], 1), _c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v("Dispatch Status")]), _c('v-list-item-subtitle', [_vm._v(" The status which marks a job to be dispatched. ")])], 1), _c('v-list-item-action', [_c('LazyDropdown', {
    attrs: {
      "url": 'jobStatus',
      "placeholder": "Status",
      "item-text": "name",
      "item-value": "xid",
      "outlined": "",
      "dense": ""
    },
    on: {
      "input": _vm.updateDispatchStatus
    },
    model: {
      value: _vm.workflow.dispatch_status,
      callback: function callback($$v) {
        _vm.$set(_vm.workflow, "dispatch_status", $$v);
      },
      expression: "workflow.dispatch_status"
    }
  })], 1)], 1), _c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v("Invoicing Status")]), _c('v-list-item-subtitle', [_vm._v(" The status which marks a job to be invoiced. ")])], 1), _c('v-list-item-action', [_c('LazyDropdown', {
    attrs: {
      "url": 'jobStatus',
      "placeholder": "Status",
      "item-text": "name",
      "item-value": "xid",
      "outlined": "",
      "dense": ""
    },
    on: {
      "input": _vm.updateInvoicingStatus
    },
    model: {
      value: _vm.workflow.invoicing_status,
      callback: function callback($$v) {
        _vm.$set(_vm.workflow, "invoicing_status", $$v);
      },
      expression: "workflow.invoicing_status"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }