<template>
  <v-container fluid class='pt-0 overflow-y-auto'>

    <v-card style='max-width:1200px;' :disabled='loading' :loading='loading'>
      <v-card-title class='primary white--text text-h6'>
        <v-icon class='mr-2' dark>mdi-transit-connection-horizontal</v-icon>Workflow
      </v-card-title>
      <v-card-text>
        <v-list class='transparent'>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Import Job Details - Default Status</v-list-item-title>
              <v-list-item-subtitle>
                The status suggested when importing jobs details from a prescription.
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <LazyDropdown :url="'jobStatus'" v-model="workflow.import_status" @input='updateImportStatus' placeholder="Status" item-text="name" item-value="xid" outlined dense />
            </v-list-item-action>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Import Job Details - QMS Step</v-list-item-title>
              <v-list-item-subtitle>
                Automatically log a QMS activity for the user who imports job details from a prescription.
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <LazyDropdown :url="'qms/steps'" v-model="workflow.import_step" @input='updateImportStep' placeholder="QMS Step" item-text="title" item-value="xid" outlined dense />
            </v-list-item-action>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Completion Status</v-list-item-title>
              <v-list-item-subtitle>
                The status a job moves to when the <i>Complete Job</i> automation is run.
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <LazyDropdown :url="'jobStatus'" v-model="workflow.completed_status" @input='updateCompletedStatus' placeholder="Status" item-text="name" item-value="xid" outlined dense />
            </v-list-item-action>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Completion Step</v-list-item-title>
              <v-list-item-subtitle>
                The QMS Step to log when the <i>Complete Job</i> automation is run.
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <LazyDropdown :url="'/qms/steps'" v-model="workflow.completed_step" @input='updateCompletedStep' placeholder="QMS Step" item-text="title" item-value="xid" outlined dense />
            </v-list-item-action>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Dispatch Status</v-list-item-title>
              <v-list-item-subtitle>
                The status which marks a job to be dispatched.
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <LazyDropdown :url="'jobStatus'" v-model="workflow.dispatch_status" @input='updateDispatchStatus' placeholder="Status" item-text="name" item-value="xid" outlined dense />
            </v-list-item-action>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Invoicing Status</v-list-item-title>
              <v-list-item-subtitle>
                The status which marks a job to be invoiced.
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <LazyDropdown :url="'jobStatus'" v-model="workflow.invoicing_status" @input='updateInvoicingStatus' placeholder="Status" item-text="name" item-value="xid" outlined dense />
            </v-list-item-action>
          </v-list-item>

        </v-list>
      </v-card-text>
    </v-card>

  </v-container>
</template>

<style lang="scss">
//@import "@/assets/common";
</style>

<script>
import { mapActions } from 'vuex'
import LazyDropdown from '@/components/LazyDropdown'
export default {
  name: 'Template',

  components: {
    LazyDropdown
  },

  data: () => ({
    loading: false,
    workflow: {
      import_status: null,
      import_step: null,
      completed_step: null,
      completed_status: null,
      dispatch_status: null,
      invoicinig_status: null,
    },

  }),

  computed: {
  },

  methods:{
    ...mapActions("API", ["API"]),

    refreshWorkflow(){
      this.loading = true;
      this.API({ method: 'GET', endpoint: `account/workflow` })
      .then(workflow => { this.workflow = workflow; })
      .finally(()=>{ this.loading = false; });
    },

    updateImportStatus(v){ this.update('import_status', v?.xid || null); },
    updateImportStep(v){ this.update('import_step', v?.xid || null); },
    updateCompletedStatus(v){ this.update('completed_status', v?.xid || null); },
    updateCompletedStep(v){ this.update('completed_step', v?.xid || null); },
    updateDispatchStatus(v){ this.update('dispatch_status', v?.xid || null); },
    updateInvoicingStatus(v){ this.update('invoicing_status', v?.xid || null); },
    


    update(field, value){
      this.loading = true;
      let data = { [field]: value };
      this.API({ method: 'PATCH', endpoint: `account/workflow`, data })
      .finally(()=>{ this.loading = false; });
    }

  },

  mounted() {
    this.refreshWorkflow();
  },

};
</script>
